/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {Router, Route, Switch} from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// pages for this product
import HomePage from "./views/HomePage/HomePage";
import InternetTariffs from "./views/InternetTariff/InternetTariffs";
import CenikIptv from "./views/TariffsIptv/CenikIptv";
import CenikInternet from "./views/InternetTariff/CenikInternet";

var hist = createBrowserHistory();

ReactDOM.render(
    <Router history={hist}>
        <Switch>
            <Route path="/internet" component={InternetTariffs}/>
            <Route path="/cenik_iptv" component={CenikIptv} />
            <Route path="/cenik_internet" component={CenikInternet} />
            <Route path="/" component={HomePage}/>
        </Switch>
    </Router>,
    document.getElementById("root")
);
