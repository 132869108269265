import React from "react";
import classNames from "classnames";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js";
import {Link} from "react-router-dom";

const useStyles = makeStyles(styles);


export default function RapidFooter() {
    const classes = useStyles();

    return (
        <Footer
            theme={'transparent'}
            content={
                <div>
                    <ul className={classes.socialButtons}>
                        <li>
                            <Button justIcon simple href="#pablo" color="twitter">
                                <i className="fab fa-twitter"/>
                            </Button>
                        </li>
                        <li>
                            <Button justIcon simple href="#pablo" color="facebook">
                                <i className="fab fa-facebook-square"/>
                            </Button>
                        </li>
                        <li>
                            <Button justIcon simple href="#pablo" color="google">
                                <i className="fab fa-google-plus-g"/>
                            </Button>
                        </li>
                    </ul>
                    <div
                        className={classNames(classes.pullCenter, classes.copyRight)}
                    >
                        Copyright &copy; {1900 + new Date().getYear()}{" "}
                        <a href="https://www.orkasolutions.cz">ORKA Solutions, s.r.o.</a> All
                        Rights Reserved.
                    </div>
                </div>
            }
        >
            <div className={classes.footer}>
                <GridContainer>
                    <GridItem xs={12} sm={3} md={3}>
                            <h5>ORKA Systems, s.r.o.</h5>
                        <p>
                            Ič: 272 748 61
                        </p>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <h5>Další informace</h5>
                        <ul className={classes.linksVertical}>
                            <li>
                                <a href="#pablo">Zpracování osobních údajů</a>
                            </li>
                            <li>
                                <a href="#pablo">Síťová rozhraní datových služeb</a>
                            </li>
                            <li>
                                <a href="#pablo">Nastavení počítače</a>
                            </li>
                        </ul>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <h5>Naše tarify</h5>
                        <ul className={classes.linksVertical}>
                            <li>
                                <a href="#pablo">Tarify Internet</a>
                            </li>
                            <li>
                                <a href="/doc/cenik_iptv.pdf" target={'_blank'}>Tarify IPTV</a>
                            </li>
                        </ul>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <h5>Soubory ke stažení</h5>
                        <ul className={classes.linksVertical}>
                            <li>
                                <Link to={'/cenik_internet'}>
                                    Ceník Internet
                                </Link>
                            </li>
                            <li>
                                <Link to={'/cenik_iptv'}>
                                    Ceník IPTV
                                </Link>
                            </li>
                            <li>
                                <a href="#pablo">Programová skladba IPTV</a>
                            </li>
                            <li>
                                <a href="#pablo">Manuál k IPTV</a>
                            </li>
                            <li>
                                <a href="#pablo">Všeobecné podmínky</a>
                            </li>
                            <li>
                                <a href="#pablo">Vzdálená pomoc (TW)</a>
                            </li>
                        </ul>
                    </GridItem>
                </GridContainer>
            </div>


        </Footer>
    )
}
