import React from "react";

import PdfViewer from "../../ui/PdfViewer/PdfViewer";
import cenikInternet from 'assets/dokumenty/cenik_internet.pdf'


export default function CenikInternet() {
    return (
       <div>
        <PdfViewer pdf={cenikInternet} />
        </div>
    )
}