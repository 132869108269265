/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

import pricingStyle from "assets/jss/material-kit-pro-react/views/pricingStyle.js";
// Rapidnet UI
import RapidHeader from "../../ui/RapidHeader";
import RapidFooter from "../../ui/RapidFooter";

const useStyles = makeStyles(pricingStyle);

export default function InternetTariffs() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const classes = useStyles();
    return (
        <div>
            <RapidHeader/>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <div>
                        <h1>
                            Podrobnosti Tarify Internet
                        </h1>
                    </div>
                </div>
            </div>
            <RapidFooter/>
        </div>
    );
}