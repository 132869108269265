import Header from "../components/Header/Header";
import {brandTitle} from "../assets/jss/material-kit-pro-react";
import React from "react";
import RapidHeaderLinks from "./RapidHeaderLinks";


export default function () {
    return (
        <Header
            color="transparent"
            brand={brandTitle}
            links={<RapidHeaderLinks dropdownHoverColor="rose"/>}
            fixed
            changeColorOnScroll={{
                height: 300,
                color: "rose"
            }}
        />
    )
}