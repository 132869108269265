import React from "react";
// nodejs library that concatenates classes
// react component for creating beautiful carousel
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";

import headImg from 'assets/img/network-rapidnet.jpg'
const useStyles = makeStyles(headersStyle);

export default function SectionHeaders({...rest}) {
    const classes = useStyles();
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: false
    // };
    return (
        // we've set the className to cd-section so we can make smooth scroll to it
        <div className="cd-section" {...rest}>
            {/*<div className={classes.sectionBlank} />*/}
            {/*<Parallax image={require("assets/img/network-rapidnet.jpg")} filter="dark">*/}
            {/*    <div className={classes.container}>*/}
            {/*        <GridContainer>*/}
            {/*            <GridItem xs={12} sm={8} md={10}>*/}
            {/*                <h1 className={classes.title}>Jsme Váš poskytovatel internetu</h1>*/}
            {/*                <h4>*/}
            {/*                    Poskytujeme internetové připojení v Karviné a blízkém okolí*/}
            {/*                </h4>*/}
            {/*                <br/>*/}
            {/*            </GridItem>*/}
            {/*        </GridContainer>*/}
            {/*    </div>*/}
            {/*</Parallax>*/}


            {/* HEADER 1 START */}
            <div>
                <div
                    className={classes.pageHeader}
                    style={{backgroundImage: `url("${headImg}")`}}
                >
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={8} md={10}>
                            <h1 className={classes.title}>Jsme Váš poskytovatel internetu</h1>
                            <h4>
                                Poskytujeme internetové připojení v Karviné a blízkém okolí
                            </h4>
                            <br/>
                        </GridItem>
                    </GridContainer>
                </div>
                {/*        <div className={classes.container}>*/}
                {/*            <GridContainer>*/}
                {/*                <GridItem xs={12} sm={6} md={6}>*/}
                {/*                    <h1 className={classes.title}>Tesla Model 3.</h1>*/}
                {/*                    <h4>*/}
                {/*                        There{"'"}s no doubt that Tesla is delighted with the*/}
                {/*                        interest, but the data also raises a few questions. How long*/}
                {/*                        will it take for Tesla to fulfill all those extra orders?*/}
                {/*                    </h4>*/}
                {/*                    <br/>*/}
                {/*                    <Button*/}
                {/*                        color="danger"*/}
                {/*                        size="lg"*/}
                {/*                        href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"*/}
                {/*                        target="_blank"*/}
                {/*                        rel=""*/}
                {/*                    >*/}
                {/*                        <i className="fas fa-ticket-alt"/>*/}
                {/*                        Order Now*/}
                {/*                    </Button>*/}
                {/*                </GridItem>*/}
                {/*                <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>*/}
                {/*                    <div className={classes.iframeContainer}>*/}
                {/*                        <iframe*/}
                {/*                            height="250"*/}
                {/*                            src="https://www.youtube.com/embed/IN6QnLpVEPI?ref=creativetim"*/}
                {/*                            frameBorder="0"*/}
                {/*                            allow="encrypted-media"*/}
                {/*                            allowFullScreen=""*/}
                {/*                            title="Tesla"*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                </GridItem>*/}
                {/*            </GridContainer>*/}
                {/*        </div>*/}
                </div>
            </div>
            {/* HEADER 1 END */}
        </div>
    );
}