import React from "react";
import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader";
import {cardTitle} from "../assets/jss/material-kit-pro-react";
import CardBody from "../components/Card/CardBody";
import Button from "../components/CustomButtons/Button";

export default function ProgramyTvCard(props) {
    const tvnabidka = props.tvnabidka;

    return (
        <Card
            raised
            pricing
            color={'rose'}
            style={{maxWidth: "435px", align: "center"}}
        >
            <CardHeader color={'success'} >
                <h4 className={cardTitle}> {tvnabidka.caption} </h4>
            </CardHeader>
            <CardBody >
                <ul>
                    <li>
                        {tvnabidka.subCaption}
                    </li>
                    <li>
                        <h3>{tvnabidka.priceText}</h3>
                    </li>
                    <li>
                        měsíčně
                    </li>
                </ul>
                <Button color={'secondary'}>
                    Více informací
                </Button>
            </CardBody>
        </Card>
    )
}


