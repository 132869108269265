import React from "react";

import PdfViewer from "../../ui/PdfViewer/PdfViewer";
import cenikIptv from 'assets/dokumenty/cenik_internet.pdf'


export default function CenikIptv() {
    return (
       <div>
        <PdfViewer pdf={cenikIptv} />
        </div>
    )
}