import React from "react";

import SinglePagePDFViewer from "../../components/Pdf/singlePage";

export default function PdfViewer(props) {

    return (
        <div>
            <SinglePagePDFViewer pdf={props.pdf} />
        </div>)

}