const tariffsInternet = [

    {
        name: 'tariffLan',
        caption: "RapidNet LAN",
        subCaption: "Ideální pro bytové domy",
        priceText: 'od 250,- Kč',
        downloadMax: 50,
        uploadMax: 10,
        variants: [
            {
                name: 'lan30',
                caption: 'LAN 30',
                speedDown: 30,
                speedDownReal: 18,
                speedDownMin: 9,
                speedUp: 30,
                speedUpReal: 18,
                speedUpMin: 9,
                price: 250
            },
            {
                name: 'lan50',
                caption: 'LAN 50',
                speedDown: 30,
                speedDownReal: 18,
                speedDownMin: 9,
                speedUp: 30,
                speedUpReal: 18,
                speedUpMin: 9,
                price: 250
            },
            {
                name: 'lanSenior',
                caption: 'LAN SENIOR',
                speedDown: 30,
                speedDownReal: 18,
                speedDownMin: 9,
                speedUp: 30,
                speedUpReal: 18,
                speedUpMin: 9,
                price: 250
            },

        ]
    },
    {
        name: 'tariffSolo',
        caption: "RapidNet SOLO",
        subCaption: "Pro rodinné domy a jednotlivce",
        priceText: 'od 250,- Kč',
        downloadMax: 40,
        uploadMax: 5,

    },

    {
        name: 'tariffFiber',
        caption: "RapidNet FIBER",
        subCaption: "Pro domy připojené optikou",
        priceText: 'od 250,- Kč',
        downloadMax: 200,
        uploadMax: 100,

    },
]

const iptvDefault = [
    {
        name: 'iptvbasic',
        caption: "IPTV BASIC",
        subCaption: "89 programů, z toho 29 v HD kvalitě",
        priceText: '109,- Kč',
    },
    {
        name: 'iptvstandard',
        caption: "IPTV STANDARD",
        subCaption: "128 programů, z toho 39 v HD kvalitě",
        priceText: '399,- Kč',
    },
    {
        name: 'iptvmax',
        caption: "IPTV MAX",
        subCaption: "153 programů, z toho 52 v HD kvalitě",
        priceText: '579,- Kč',
    },
]
export default tariffsInternet;
export {iptvDefault};

