import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";
import tariffIptvStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/tariffIptvStyle.js";

import ProgramyTvCard from "../../../ui/ProgramyTvCard";
import {iptvDefault} from "../../../dataIptv";


const style = {
    ...teamsStyle,
    ...tariffIptvStyle,
    justifyContentCenter: {
        justifyContent: "center"
    }
};

const useStyles = makeStyles(style);

export default function SectionTarifyIptv({ ...rest }) {
    const classes = useStyles();

    const iptvDefaultCards = (
        <React.Fragment>
            {iptvDefault.map((program, i) => (
                <GridItem xs={12} sm={4} md={4} key={`${program}${i}`}>
                    <ProgramyTvCard
                        tvnabidka={program}
                        style={{maxWidth: "435px", align: "center"}}
                    />
                </GridItem>

            ))}
        </React.Fragment>
    );

    return (
        <div className="cd-section" {...rest}>
            <GridContainer justify="center">
                <GridItem className={classes.section}>
                    <h2 className={classes.title}>Nabídka televizních programů</h2>
                </GridItem>
            </GridContainer>
            <GridContainer justify="center">
                {iptvDefaultCards}
            </GridContainer>
        </div>

    );
}
