import {
    container,
    title,
    description,
    section,
    btnLink,
    twitterColor,
    dribbbleColor,
    instagramColor,
    grayColor
} from "assets/jss/material-kit-pro-react.js";

import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

const style = {
    ...imagesStyles,
    container,
    title,
    description,
    section: {
        ...section,
        padding: "70px 0px"
    },
    socialFeed: {
        "& p": {
            display: "table-cell",
            verticalAlign: "top",
            overflow: "hidden",
            paddingBottom: "10px",
            maxWidth: 300
        },
        "& i": {
            fontSize: "20px",
            display: "table-cell",
            paddingRight: "10px"
        }
    },
    img: {
        width: "20%",
        marginRight: "5%",
        marginBottom: "5%",
        float: "left"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
    },
    left: {
        float: "left!important",
        display: "block"
    },
    right: {
        padding: "15px 0",
        margin: "0",
        float: "right"
    },
    aClass: {
        textDecoration: "none",
        backgroundColor: "transparent"
    },
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "3px",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
    footerBrand: {
        height: "50px",
        padding: "15px 15px",
        fontSize: "18px",
        lineHeight: "50px",
        marginLeft: "-15px",
        color: grayColor[1],
        textDecoration: "none",
        fontWeight: 700,
        fontFamily: "Roboto Slab,Times New Roman,serif"
    },
    pullCenter: {
        display: "inline-block",
        float: "none"
    },
    rightLinks: {
        float: "right!important",
        "& ul": {
            marginBottom: 0,
            padding: 0,
            listStyle: "none",
            "& li": {
                display: "inline-block"
            },
            "& a": {
                display: "block"
            }
        },
        "& i": {
            fontSize: "20px"
        }
    },
    linksVertical: {
        "& li": {
            display: "block !important",
            textTransform: 'none',
            marginLeft: "-5px",
            marginRight: "-5px",
            "& a": {
                padding: "5px !important"
            }
        }
    },
    footer: {
        "& ul li": {
            display: "inline-block"
        },
        "& h4, & h5": {
            color: grayColor[1],
            textDecoration: "none"
        },
        "& ul:not($socialButtons) li a": {
            color: "inherit",
            padding: "0.9375rem",
            fontWeight: "500",
            fontSize: "12px",
            textTransform: "uppercase",
            borderRadius: "3px",
            textDecoration: "none",
            position: "relative",
            display: "block"
        },
        "& small": {
            fontSize: "75%",
            color: grayColor[10]
        },
        "& $pullCenter": {
            display: "inline-block",
            float: "none"
        }
    },
    iconSocial: {
        width: "41px",
        height: "41px",
        fontSize: "24px",
        minWidth: "41px",
        padding: 0,
        overflow: "hidden",
        position: "relative"
    },
    copyRight: {
        padding: "15px 0px"
    },
    socialButtons: {
        "& li": {
            display: "inline-block"
        }
    },
    btnTwitter: {
        ...btnLink,
        color: twitterColor
    },
    btnDribbble: {
        ...btnLink,
        color: dribbbleColor
    },
    btnInstagram: {
        ...btnLink,
        color: instagramColor
    },
    icon: {
        top: "3px",
        width: "18px",
        height: "18px",
        position: "relative"
    },
    customFormControl: {
        paddingTop: "14px"
    }
};

export default style;
