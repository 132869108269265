import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
// core components
// Sections for this page
import SectionTarifyNet from "./Sections/SectionTarifyNet.js";
import SectionTarifyIptv from "./Sections/SectionTarifyIptv.js";
import SectionWork from "./Sections/SectionWork.js";
import SectionHeaders from "./Sections/SectionHeader";
import RapidHeader from "../../ui/RapidHeader";
import RapidFooter from "../../ui/RapidFooter";
import {container, main, mainRaised, title, whiteColor} from 'assets/jss/material-kit-pro-react'
import classNames from "classnames";

const homePageStyle = {
    main: {
        ...main
    },
    mainRaised: {
        ...mainRaised
    },
    container: {
        color: whiteColor,
        ...container,
        zIndex: "2"
    },
    title: {
        ...title,
        display: "inline-block",
        position: "relative",
        marginTop: "30px",
        minHeight: "32px",
        color: whiteColor,
        textDecoration: "none"
    },
    subtitle: {
        fontSize: "1.313rem",
        maxWidth: "500px",
        margin: "10px auto 0"
    },

};


const useStyles = makeStyles(homePageStyle);

export default function HomePage() {
    React.useEffect(() => {
        var href = window.location.href.substring(
            window.location.href.lastIndexOf("#") + 1
        );
        if (window.location.href.lastIndexOf("#") > 0) {
            document.getElementById(href).scrollIntoView();
        }
        window.addEventListener("scroll", updateView);
        updateView();
        return function cleanup() {
            window.removeEventListener("scroll", updateView);
        };
    });
    const updateView = () => {
        var contentSections = document.getElementsByClassName("cd-section");
        var navigationItems = document
            .getElementById("cd-vertical-nav")
            .getElementsByTagName("a");

        for (let i = 0; i < contentSections.length; i++) {
            var activeSection =
                parseInt(navigationItems[i].getAttribute("data-number"), 10) - 1;
            if (
                contentSections[i].offsetTop - window.innerHeight / 2 <
                window.pageYOffset &&
                contentSections[i].offsetTop +
                contentSections[i].scrollHeight -
                window.innerHeight / 2 >
                window.pageYOffset
            ) {
                navigationItems[activeSection].classList.add("is-selected");
            } else {
                navigationItems[activeSection].classList.remove("is-selected");
            }
        }
    };
    const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    };
    const smoothScroll = target => {
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
    };

    const scrollGo = (element, to, duration) => {
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        var animateScroll = function () {
            currentTime += increment;
            var val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    };
    const classes = useStyles();
    return (
        <div>
            <RapidHeader/>
            <div className={classNames(classes.main)}>
                <SectionHeaders id="header"/>
                <div className={classes.container}>
                    <SectionTarifyNet id="internet"/>
                    <SectionTarifyIptv id="iptv"/>
                    <SectionWork id="services"/>
                </div>
            </div>
            <nav id="cd-vertical-nav">
                <ul>
                    <li>
                        <a
                            href="#header"
                            data-number="1"
                            className="is-selected"
                            onClick={e => {
                                var isMobile = navigator.userAgent.match(
                                    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                                );
                                if (isMobile) {
                                    // if we are on mobile device the scroll into view will be managed by the browser
                                } else {
                                    e.preventDefault();
                                    smoothScroll("header");
                                }
                            }}
                        >
                            <span className="cd-dot"/>
                            <span className="cd-label">Nahoru</span>
                        </a>
                    </li>
                    <li>
                        <a
                            href="#internet"
                            data-number="1"
                            className="is-selected"
                            onClick={e => {
                                var isMobile = navigator.userAgent.match(
                                    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                                );
                                if (isMobile) {
                                    // if we are on mobile device the scroll into view will be managed by the browser
                                } else {
                                    e.preventDefault();
                                    smoothScroll("internet");
                                }
                            }}
                        >
                            <span className="cd-dot"/>
                            <span className="cd-label">Internet</span>
                        </a>
                    </li>
                    <li>
                        <a
                            href="#iptv"
                            data-number="2"
                            className=""
                            onClick={e => {
                                var isMobile = navigator.userAgent.match(
                                    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                                );
                                if (isMobile) {
                                    // if we are on mobile device the scroll into view will be managed by the browser
                                } else {
                                    e.preventDefault();
                                    smoothScroll("iptv");
                                }
                            }}
                        >
                            <span className="cd-dot"/>
                            <span className="cd-label">Televize</span>
                        </a>
                    </li>
                    <li>
                        <a
                            href="#services"
                            data-number="3"
                            className=""
                            onClick={e => {
                                var isMobile = navigator.userAgent.match(
                                    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                                );
                                if (isMobile) {
                                    // if we are on mobile device the scroll into view will be managed by the browser
                                } else {
                                    e.preventDefault();
                                    smoothScroll("services");
                                }
                            }}
                        >
                            <span className="cd-dot"/>
                            <span className="cd-label">Služby</span>
                        </a>
                    </li>
                </ul>
            </nav>
            <RapidFooter clasess={classes}/>

        </div>
    );
}