/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import {Link} from "react-router-dom";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import ViewDay from "@material-ui/icons/ViewDay";
import Dns from "@material-ui/icons/Dns";
import Build from "@material-ui/icons/Build";
import ListIcon from "@material-ui/icons/List";
import People from "@material-ui/icons/People";
import Assignment from "@material-ui/icons/Assignment";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import Chat from "@material-ui/icons/Chat";
import Call from "@material-ui/icons/Call";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import AccountBalance from "@material-ui/icons/AccountBalance";
import ArtTrack from "@material-ui/icons/ArtTrack";
import ViewQuilt from "@material-ui/icons/ViewQuilt";
import LocationOn from "@material-ui/icons/LocationOn";
import Fingerprint from "@material-ui/icons/Fingerprint";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Store from "@material-ui/icons/Store";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Layers from "@material-ui/icons/Layers";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import LineStyle from "@material-ui/icons/LineStyle";
import Error from "@material-ui/icons/Error";
import Cloud from '@material-ui/icons/Cloud';
import Mail from '@material-ui/icons/Mail';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function RapidHeaderLinks(props) {
    const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const smoothScroll = (e, target) => {
        if (window.location.pathname === "/") {
            var isMobile = navigator.userAgent.match(
                /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
            );
            if (isMobile) {
                // if we are on mobile device the scroll into view will be managed by the browser
            } else {
                e.preventDefault();
                var targetScroll = document.getElementById(target);
                scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
            }
        }
    };
    const scrollGo = (element, to, duration) => {
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        var animateScroll = function () {
            currentTime += increment;
            var val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    };
    var onClickSections = {};

    const {dropdownHoverColor} = props;
    const classes = useStyles();
    return (
        <List className={classes.list + " " + classes.mlAuto}>
            <ListItem className={classes.listItem}>
                <Button
                    className={classes.navLink}
                    color="transparent"
                    onClick={e => smoothScroll(e, "internet")}
                >
                    Internet
                </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Button
                    className={classes.navLink}
                    onClick={e => smoothScroll(e, "iptv")}
                    color="transparent"
                >
                    Televize
                </Button>

            </ListItem>
            <ListItem className={classes.listItem}>
                <CustomDropdown
                    noLiPadding
                    navDropdown
                    hoverColor={dropdownHoverColor}
                    buttonText="Můj RapidNet"
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    buttonIcon={AccountBalance}
                    dropdownList={[
                        <a href="https://lms.rapidnet.cz/userpanel/" target={'_blank'} className={classes.dropdownLink}>
                            <ArtTrack className={classes.dropdownIcons}/> Účet RapidNet
                        </a>,
                        <a href="https://live.rapidnet.tv/" target={'_blank'} className={classes.dropdownLink}>
                            <ViewQuilt className={classes.dropdownIcons}/> Nastavení účtu IPTV
                        </a>,
                        <a href="https://mail.rapidnet.cz/" target={'_blank'} className={classes.dropdownLink}>
                            <Mail className={classes.dropdownIcons}/> Pošta RapidNet
                        </a>,
                        <a href="https://speedtest.rapidnet.cz/" target={'_blank'} className={classes.dropdownLink}>
                            <ViewDay className={classes.dropdownIcons}/> Test rychlosti připojení
                        </a>,
                        <a href="https://get.teamviewer.com/kk9r8ey" target={'_blank'} className={classes.dropdownLink}>
                            <Fingerprint className={classes.dropdownIcons}/> Vzdálená pomoc
                        </a>,
                    ]}
                />
            </ListItem>
            <ListItem className={classes.listItem}>
                <Button
                    color={window.innerWidth < 960 ? "info" : "white"}
                    target="_blank"
                    className={classes.navButton}
                    round
                >
                    <Cloud className={classes.icons}/> Chci připojit
                </Button>
            </ListItem>
        </List>
    );
}

RapidHeaderLinks.defaultProps = {
    hoverColor: "primary"
};

RapidHeaderLinks.propTypes = {
    dropdownHoverColor: PropTypes.oneOf([
        "dark",
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "rose"
    ])
};
