import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import tariffNetStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/tariffNetStyle.js";
import tariffsInternet from '../../../dataTariffs';
import TariffCard from "../../../ui/TariffCard";

const style = {
  ...tariffNetStyle
};

const useStyles = makeStyles(style);

export default function SectionTarifyNet({ ...rest }) {
    const classes = useStyles();

    const internetCards = (
        <React.Fragment>
            {tariffsInternet.map((tarif, i) => (
                <GridItem xs={12} sm={4} md={4} key={`${tarif}${i}`}>
                    <TariffCard
                        tariff={tarif}
                        link={'/tariff_internet'}
                        style={{maxWidth: "435px", align: "center"}}
                    />
                </GridItem>

            ))}
        </React.Fragment>
    );

    return (
        <div className="cd-section" {...rest}>
            <GridContainer justify="center">
                <GridItem className={classes.section}>
                    <h2 className={classes.title}>Nabídka internetových tarifů</h2>
                </GridItem>
            </GridContainer>
            <GridContainer justify="center">
                {internetCards}
            </GridContainer>
        </div>
    );
}
