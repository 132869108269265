const iptvDefault = [
    {
        name: 'iptvbasic',
        caption: "IPTV BASIC",
        subCaption: "89 programů, z toho 29 v HD kvalitě",
        priceText: '109,- Kč',
    },
    {
        name: 'iptvstandard',
        caption: "IPTV STANDARD",
        subCaption: "128 programů, z toho 39 v HD kvalitě",
        priceText: '399,- Kč',
    },
    {
        name: 'iptvmax',
        caption: "IPTV MAX",
        subCaption: "153 programů, z toho 52 v HD kvalitě",
        priceText: '579,- Kč',
    },
];

const iptvBalicky = [
    {
        name: "sport",
        caption: "SPORT",
        subCaption: "9 programů",
        priceText: '79,- Kč',
    },
    {
        name: "zpravodaj",
        caption: "ZPRAVODAJ",
        subCaption: "6 programů",
        priceText: '29,- Kč',
    },
    {
        name: "film",
        caption: "FILM",
        subCaption: "7 programů",
        priceText: '99,- Kč',
    },
    {
        name: "superbox",
        caption: "SUPERBOX",
        subCaption: "9 programů",
        priceText: '189,- Kč',
    },
    {
        name: "dokument",
        caption: "DOKUMENT",
        subCaption: "12 programů",
        priceText: '109,- Kč',
    },
    {
        name: "child",
        caption: "DĚTSKÉ",
        subCaption: "5 programů",
        priceText: '49,- Kč',
    },
    {
        name: "cesky",
        caption: "ČESKY",
        subCaption: "4 programů",
        priceText: '49,- Kč',
    },
    {
        name: "sex",
        caption: "18 +",
        subCaption: "4 programů",
        priceText: '59,- Kč',
    },

]
export {iptvDefault, iptvBalicky};


