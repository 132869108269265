import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import workStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(workStyle);

export default function SectionWork({...rest}) {
    const classes = useStyles();
    return (
        <div className="cd-section" {...rest}>
            <GridContainer justify="center">
                <GridItem cs={12} sm={8} md={8}>
                    <h2 className={classes.title}>Nabídka služeb</h2>
                    <h4 className={classes.description}>
                        Divide details about your product or agency work into parts. Write a
                        few lines about each one and contact us about any further
                        collaboration. We will responde get back to you in a couple of
                        hours.
                    </h4>
                    <form>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <CustomInput
                                    labelText="Your Name"
                                    id="name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <CustomInput
                                    labelText="Your Email"
                                    id="email"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <CustomInput
                                labelText="Your Message"
                                id="message"
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.textArea
                                }}
                                inputProps={{
                                    multiline: true,
                                    rows: 5
                                }}
                            />
                            <GridItem
                                xs={12}
                                sm={4}
                                md={4}
                                className={classes.mrAuto + " " + classes.mlAuto}
                            >
                                <Button color="primary">Send Message</Button>
                            </GridItem>
                        </GridContainer>
                    </form>
                </GridItem>
            </GridContainer>
        </div>
    );
}
