import React from "react";
import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader";
import {cardTitle} from "../assets/jss/material-kit-pro-react";
import CardBody from "../components/Card/CardBody";
import Button from "../components/CustomButtons/Button";
import {Link} from "react-router-dom";


export default function TariffCard(props) {
    const tariff = props.tariff;

    return (
        <Card
            raised
            pricing
            color={'rose'}
            style={{maxWidth: "435px", align: "center"}}
        >
            <CardHeader color={'warning'}>
                <h4 className={cardTitle}> {tariff.caption} </h4>
            </CardHeader>
            <CardBody>
                <ul>
                    <li>
                        {tariff.subCaption}
                    </li>
                    <li>
                        <h3>{tariff.priceText}</h3>
                    </li>
                    <li>
                        Rychlost stahování až {tariff.downloadMax} Mb/s
                    </li>
                </ul>
                <Button color={'secondary'} round>
                    <Link to='/internet'>
                        Více informací
                    </Link>

                </Button>
            </CardBody>
        </Card>
    )
}


